import classes from "./CSS/PageWrapper.module.css";

const PageWrapper = (props) => {
  // const parentStyle = props.cssStyle;
  // console.log(parentStyle);

  return (
      <div>
        <div className={classes.center}>{props.children}</div>
      </div>
  );
};
export default PageWrapper;

//  const classes = 'Center ' + props.className;

//const classes = 'center ' + props.sccStyle;
//className={`${styles.description} ${styles.yellow}`}

//==========
// return <div className={classes.center}>{props.children}</div>;
