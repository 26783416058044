import PageWrapper from "../UI/PageWrapper";
import classes from "./CSS/NotFound.module.css";

const NotFound = (props) => {
  return (
    <PageWrapper>
      <div>
        <h1 vclassName={classes.Main}>This is the NotFound</h1>
      </div>
    </PageWrapper>
  );
};

export default NotFound;
