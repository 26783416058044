import { useNavigate } from "react-router-dom";
import classes from "./CSS/BtnWrap.module.css";

import * as Consts from '../ConstClass';


const BtnWrap = (props) => {
  const navigate = useNavigate();

  const onClickHandler = (event) => {
      navigate(`/${event.target.name}Page`);
  };

  return (
    <div className={classes.box}>
      <button
        name={Consts.COMBAT}
        onClick={onClickHandler}
        className={classes.combatButton}
      >
        {Consts.COMBAT}
      </button>
      <button
        name={Consts.EXPLORE}
        onClick={onClickHandler}
        className={classes.exploreButton}
      >
        {Consts.EXPLORE}
      </button>
      <button
        name={Consts.INVENTORY}
        onClick={onClickHandler}
        className={classes.inventoryButton}
      >
        {Consts.INVENTORY}
      </button>
      <button
        name={Consts.MAGIC}
        onClick={onClickHandler}
        className={classes.magicButton}
      >
        {Consts.MAGIC}
      </button>

      {props.children}
      <button name= {Consts.MAIN} onClick={onClickHandler} className={classes.center}>
      {Consts.MAIN}
      </button>
    </div>
  );
};
export default BtnWrap;

