import PageWrapper from "../UI/PageWrapper";
import classes from "./CSS/MainPage.module.css";

const MainPage = (props) => {
  return (
    <PageWrapper>
      <div>
        <h1 className={classes.Main}>This is the MainPage</h1>
      </div>
    </PageWrapper>
  );
};

export default MainPage;
