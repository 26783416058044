import PageWrapper from "../UI/PageWrapper";
import classes from "./CSS/MagicPage.module.css";

const MagicPage = (props) => {
  return (
    <PageWrapper>
      <div>
        <h1 className={classes.Main}>This is the MagicPage</h1>
      </div>
    </PageWrapper>
  );
};

export default MagicPage;
