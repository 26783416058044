import PageWrapper from "../UI/PageWrapper";

import { useState } from "react";
import WeaponsList from "../Components/WeaponsList";
import classes from "./CSS/CombatPage.module.css";

const weaponsDummyData = [
  { name: "Axe", bonus: +5, dmgType: "slash", id: 1 },
  { name: "Sword", bonus: +3, dmgType: "slash", id: 2 },
  { name: "Dagger", bonus: +1, dmgType: "pierce", id: 3 },
  { name: "Knife", bonus: +2, dmgType: "pierce", id: 4 },
  { name: "Fireball", bonus: +0, dmgType: "fire", id: 5 },
  { name: "Acid splash", bonus: +2, dmgType: "Acid", id: 6 },
];

const CombatPage = (props) => {
  const [weapons, setWeapons] = useState(weaponsDummyData);

  const handleDelete = (id) => {
    const newWeapons = weaponsDummyData.filter((weapon) => weapon.id !== id);
    setWeapons(newWeapons);
  };

  return (
    <PageWrapper>
      <div className={classes.Main}>
        <h1>This is the CombatPage</h1>
        <WeaponsList
          listData={weapons}
          title="All The wepons"
          handleDelete={handleDelete}
        />
        <WeaponsList
          listData={weapons.filter((weapon) => weapon.dmgType === "slash")}
          title="only the slashing ones"
        />
      </div>
    </PageWrapper>
  );
};

export default CombatPage;

// const deleteHandler = () =>{};
//   <button onClick={() => {deleteHandler(weapon.id)}}>Delete weapon</button>
