import PageWrapper from "../UI/PageWrapper";
import classes from "./CSS/ExplorePage.module.css";

const ExplorePage = (props) => {
  return (
    <PageWrapper>
      <div>
        <h1 className={classes.Main}>This is the ExplorePage</h1>
      </div>
    </PageWrapper>
  );
};

export default ExplorePage;
