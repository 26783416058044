import classes from "./CSS/WeaponsList.module.css";



const WeaponsList = ({listData, title,handleDelete}) => {

  
  return (
    <div className={classes.main}>
      <h2>{ title }</h2>
      <div>
        {listData.map((weapon) => (
          <div key={weapon.id}>
            <p>
              {weapon.name} &emsp;{weapon.bonus}&emsp;{weapon.dmgType}&emsp;
              <button  onClick={()=>(handleDelete(weapon.id))}>delete</button>
            </p>
            
          </div>
        ))}
      </div>
    </div>
  );
};
export default WeaponsList;


//const WeaponsList = (props) => {