/*
    TODO:
      [_] move page names to eNum
      [_] make color names eNum
*/

//===== Page Imports =====
import CharPage from "./Pages/CharPage";
import CombatPage from "./Pages/CombatPage";
import ExplorePage from "./Pages/ExplorePage";
import InventoryPage from "./Pages/InventoryPage";
import MagicPage from "./Pages/MagicPage";
import MainPage from "./Pages/MainPage";
import NotFound from "./Pages/NotFound";
//===== Imports =====

import { Route, Routes } from "react-router-dom";
import BtnWrap from "./UI/BtnWrap";

function App() {
  return (
    <BtnWrap>
      <div>
        <Routes>
          <Route path="/MainPage" element={<MainPage />} />
          <Route path="/CharPage" element={<CharPage />} />
          <Route path="/CombatPage" element={<CombatPage />} />
          <Route path="/ExplorePage" element={<ExplorePage />} />
          <Route path="/InventoryPage" element={<InventoryPage />} />
          <Route path="/MagicPage" element={<MagicPage />} />
          <Route path="/*" element={<NotFound />} />
          <Route path="/" element={<MainPage />} />
        </Routes>
      </div>
    </BtnWrap>
  );
}

export default App;
