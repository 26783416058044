import PageWrapper from "../UI/PageWrapper";
import classes from "./CSS/CharPage.module.css";

const CharPage = (props) => {
  return (
    <PageWrapper>
        <div>
          <h1 className={classes.Main}>This is the CharPage</h1>
        </div>
    </PageWrapper>
  );
};

export default CharPage;
